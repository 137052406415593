import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { Fragment, ReactNode, SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";
import { animateScroll } from "react-scroll";
import { Section } from "../../core/Section";
import { SectionTitle } from "../../core/SectionTitle";
import { Form, FormData } from "../../form";
import { Letter } from "../../graphics";
import { IconChevronDown } from "../../icons";
import { MdxContent } from "../../mdx-content";

const HEADER_HEIGHT_OFFSET = 120;
const ELEMENT_HEIGHT = 88;

interface JobInfo {
  jobId: string;
  title: string;
  description: string;
}

export interface JobsSectionProps {
  sectionId: string;
  title: string;
  description: string | ReactNode;
  jobs: JobInfo[];
}

export const JobsSection = (props: JobsSectionProps) => {
  const { sectionId, title, description, jobs } = props;
  const [expandedIdx, setExpandedIdx] = useState(-1);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (panelIdx) => (_: SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded && containerRef.current) {
        const containerRect = containerRef.current.offsetTop;
        animateScroll.scrollTo(containerRect - HEADER_HEIGHT_OFFSET + panelIdx * ELEMENT_HEIGHT);
      }

      setExpandedIdx(isExpanded ? panelIdx : -1);
    },
    [],
  );

  const formData: FormData = useMemo(
    () => ({
      position: jobs.map((job) => ({
        label: job.title,
        value: job.jobId,
      })),
    }),
    [jobs],
  );

  return (
    <Section sectionId={sectionId}>
      <Grid container={true}>
        <Grid item={true} xs={12} md={4}>
          <SectionTitle component="h1" title={title} Stroke={<Letter.J />} />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <Typography variant="body1">{description}</Typography>
        </Grid>
      </Grid>
      <Box mt={10}>
        <Grid ref={containerRef} container={true} justifyContent="center">
          <Grid item={true} xs={12} md={8}>
            {jobs.map((job, idx) => (
              <Fragment key={job.title}>
                <Divider />
                <Accordion
                  expanded={expandedIdx === idx}
                  onChange={handleChange(idx)}
                  sx={{ background: "transparent", paddingY: 2 }}
                >
                  <AccordionSummary
                    id={`accordion${idx}-header`}
                    aria-controls={`accordion-${idx}-content`}
                    expandIcon={<IconChevronDown />}
                  >
                    <Typography variant="h3" color="inherit">
                      {job.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MdxContent>{job.description}</MdxContent>
                  </AccordionDetails>
                </Accordion>
              </Fragment>
            ))}
            <Divider />
          </Grid>
        </Grid>
      </Box>
      <Box mt={12}>
        <Grid container={true} justifyContent="center">
          <Grid item={true} xs={12} md={8}>
            <Form type="jobs" data={formData} />
          </Grid>
        </Grid>
      </Box>
    </Section>
  );
};
