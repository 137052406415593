/* eslint-disable react/display-name */
import { MDXProvider } from "@mdx-js/react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { ReactNode } from "react";

export type Shortcodes = {
  [key: string]: (props: Record<string, unknown>) => JSX.Element;
};

export const mdxShortcodes: Shortcodes = {
  h2: (props) => (
    <Box mt={3} mb={1}>
      <Typography {...props} variant="h5" component="h2" color="primary" />
    </Box>
  ),
  h3: (props) => (
    <Box mt={3} mb={1}>
      <Typography {...props} variant="h5" component="h2" color="primary" />
    </Box>
  ),
  p: (props) => <Typography {...props} variant="body1" />,
  ul: (props) => <Box {...props} mb={3} component="ul" />,
  li: (props) => <Typography {...props} variant="body2" component="li" />,
};

interface MdxContentProps {
  shortcodes?: Shortcodes;
  children: string & ReactNode;
}

export const MdxContent = (props: MdxContentProps) => {
  const { shortcodes, children } = props;

  return (
    <MDXProvider components={shortcodes ?? mdxShortcodes}>
      <div data-sb-field-path="markdown_content" style={{ padding: 0, margin: 0 }}>
        <MDXRenderer>{children}</MDXRenderer>
      </div>
    </MDXProvider>
  );
};
