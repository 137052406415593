import React from "react";
import { JobsSectionProps } from "../components/sections/Jobs";

export const JobsSectionData: Omit<JobsSectionProps, "jobs"> = {
  sectionId: "jobs",
  title: "Jobs",
  description: (
    <>
      We are always looking for smart and fun raccoons to join our team!
      <br />
      <br />
      If no open position matches your skills, choose the &#34;I am an Almighty Raccoon&#34; position in the contact
      form here below and tell us about yourself!
    </>
  ),
};
