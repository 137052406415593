import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../components/layout/layout";
import { JobsSection } from "../components/sections/Jobs";
import { SEO } from "../components/SEO";
import { JobsSectionData } from "../data/jobs";

export const pageQuery = graphql`
  query JobsPage {
    site {
      siteMetadata {
        pages {
          jobs {
            title
            description
            keywords
          }
        }
      }
    }
    jobsMdx: allMdx(filter: { fields: { source: { eq: "jobs" } }, frontmatter: { published: { eq: true } } }) {
      nodes {
        body
        frontmatter {
          jobId
          title
        }
      }
    }
  }
`;

interface JobsPageProps extends PageProps {
  data: Queries.JobsPageQuery;
}

const JobsPage = (props: JobsPageProps) => {
  const { site, jobsMdx } = props.data;

  const { sectionId, title, description } = JobsSectionData;

  const jobs = jobsMdx.nodes.map((job) => ({
    jobId: job.frontmatter?.jobId ?? "",
    title: job?.frontmatter?.title ?? "",
    description: job.body ?? "",
  }));

  const seo = site?.siteMetadata?.pages?.jobs;

  return (
    <Layout
      ContainerProps={{
        sx: {
          backgroundImage: `
            radial-gradient(circle at 80% 30rem, #003F9E59 -30%, rgba(0, 140, 184, 0) 25%),
            radial-gradient(circle at 20% 100rem, #BE202E59 -30%, rgba(250, 0, 255, 0.05) 25%)
        `,
        },
      }}
    >
      <SEO title={seo?.title} description={seo?.description} keywords={[...(seo?.keywords ?? [])]} />
      <JobsSection sectionId={sectionId} title={title} description={description} jobs={jobs} />
    </Layout>
  );
};

export default JobsPage;
